//hide cookie policy
$(".section-cookies .btn-accept").on("click", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 1,
		}
	}).done(function (response) {
		$('.section-cookies').addClass('mod-hide').removeClass('show');
	});
});

$(".section-cookies .btn-decline").on("click", function (ev) {
	ev.preventDefault();

	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 0,
		}
	}).done(function (response) {
		$('.section-cookies').addClass('mod-hide').removeClass('show');
	});
});

// Show/hide cookie policy
$("body").on("click", ".sub-category-two .btn-save-cookie-prefs", function (e) {
	e.preventDefault();
	$('.cookie-set-message').hide();
	var data = $('.sub-category-two input:radio:checked').map(function (i, el) { return $(el).val(); }).get();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/update-cookies/",
		data: { data: parseInt(data) },
	}).done(function (response) {
		var result = JSON.parse(response);
		$('.cookie-set-message').html(result['message']);
		$('.cookie-set-message').show().fadeIn('fast').delay(5000).fadeOut('slow');
	});
});

// Ajax referral form processing
$('#contact form').submit(function (e) {
	e.preventDefault();


	// if honeypot field is filled in by spambot, do not submit
	if ($('input#Form_Email').val().length != 0) {
		return false;
	}

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {
		console.log(response);

		// If no errors
		if (response == 'success') {
			// Show thank you message
			$('#contact .wrap-thanks').fadeIn();

			// Hide Form and reset
			$('#contact form').hide().trigger('reset');

			// Remove any errors that may still be there
			$('#contact form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact form').fadeIn();
				$('#contact .wrap-thanks').hide();
			}, 10000);

		}
		else {
			$('#contact form .sub-errors').fadeIn().html(response);
		}
	});
});

// Add additional z-index to hovered marker to fix hidden boxes
if ($('#facilities').length > 0) {
	$('.sub-map-marker').each(function () {
		$(this).hover(
			function () { $(this).css('z-index', '999') },
			function () { $(this).css('z-index', '1') }
		);
	});
}

// Animation Stuff
$('ul.list-processes li .sub-copy > a.btn').each(function () {
	$(this).hover(function () {
		$(this).parent().prev('.sub-image').toggleClass('mod-hover');
	})
});

$('.sub-details a.sub-view-more').each(function () {
	$(this).hover(function () {
		$(this).parent().prev('a').find('.sub-image').toggleClass('mod-hover');
	})
});

$('#careers .btn-register').click((e) => {
	// prevent default
	e.preventDefault();

	$('.sub-register').fadeIn();
	$('.btn-register').hide();
	$('.btn-close').show();
});

$('#careers .btn-close').click((e) => {
	// prevent default
	e.preventDefault();

	$('.sub-register').fadeOut();
	$('.btn-close').hide();
	$('.btn-register').show();
});

// Start virtual tour
if ($('#facilities').length > 0) {
	$('.sub-start-tour .btn').click((e) => {
		e.preventDefault();

		$('.sub-start-tour').fadeOut();
		$('#facilities iframe').css('opacity', 1);
		$('.section-tour').addClass('mod-expand');
	});
}

// Contact directions
$('#contact .btn-directions').click((e) => {
	// prevent default
	e.preventDefault();
	// set destination
	const endDestination = encodeURIComponent('Barretts of Aspley Ltd, Woburn Road, Bedford');
	// Open new window to google maps
	window.open('//www.google.com/maps/dir/?api=1&destination=' + endDestination);
});

// Product hero carousel
if ($('#products-page .section-hero .sub-images .sub-image').length > 0) {

	$('#products-page .section-hero .sub-images').slick({
		dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		fade: false,
		arrows: false,
	});
}

// Blog hero carousel Image
if ($('#blog .section-hero .sub-images .sub-image').length > 0) {

	$('#blog .section-hero .sub-images').slick({
		dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		fade: false,
		arrows: false,
		asNavFor: '.section-hero .list-titles'
	});
}

// Blog hero carousel Text
if ($('#blog .section-hero .list-titles .item-title').length > 0) {

	$('#blog .section-hero .list-titles').slick({
		dots: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		fade: true,
		arrows: false,
		draggable: false
	});
}

// Case studies post hero carousel
if ($('#case-studies-post .section-hero .sub-images .sub-image').length > 0) {

	$('#case-studies-post .section-hero .sub-images').slick({
		dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		fade: false,
		arrows: false
	});
}

// Blog hero carousel Image
if ($('.section-case-studies-carousel .sub-images .sub-image').length > 0) {

	$('.section-case-studies-carousel .sub-images').slick({
		dots: true,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		fade: false,
		arrows: false,
		asNavFor: '.section-case-studies-carousel .list-titles'
	});
}

// Blog hero carousel Text
if ($('.section-case-studies-carousel .list-titles .item-title').length > 0) {

	$('.section-case-studies-carousel .list-titles').slick({
		dots: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 4000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					adaptiveHeight: true,
				}
			},
		],
		fade: true,
		arrows: false,
		draggable: false
	});
}

$('#blog-post .section-hero .list-image').slick({
	dots: true,
	infinite: true,
	autoplay: true,
	autoplaySpeed: 4000,
	fade: false,
	arrows: false,
});

// MOBILE //
// Contact directions
$('.header-main .sub-mobile-button').click(() => {
	$('.header-main .sub-mobile-button').toggleClass('mod-active');
	$('.header-main .nav-main').toggleClass('mod-active');
});

// Hijack all href anchor scrolling
$('[href*="#"]').on('click', function (e) {
	// prevent default event
	e.preventDefault();
	// Get link from href
	const link = $(this).prop('href').split('#').pop();
	// set the target scroll position
	const target_scroll = $('#' + link).offset().top - 100;
	// Scroll to correct position
	$('html, body').animate({ scrollTop: target_scroll }, 1000, 'swing');
})

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Identify the device as touch device
function hasTouch() {
	return 'ontouchstart' in document.documentElement
		|| navigator.maxTouchPoints > 0
		|| navigator.msMaxTouchPoints > 0;
}

// Hack to remove all hover effects on touch devices
if (hasTouch()) { // remove all :hover stylesheets
	try { // prevent exception on browsers not supporting DOM styleSheets properly
		for (var si in document.styleSheets) {
			var styleSheet = document.styleSheets[si];
			if (!styleSheet.rules) continue;

			for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
				if (!styleSheet.rules[ri].selectorText) continue;

				if (styleSheet.rules[ri].selectorText.match('btn:hover')) {
					styleSheet.deleteRule(ri);
				}
			}
		}
	} catch (ex) { }
}

// Google Maps
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [['Barrets of Aspley', lat, long, 1]];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[{ "featureType": "all", "stylers": [{ "saturation": 0 }, { "hue": "#e7ecf0" }] }, { "featureType": "road", "stylers": [{ "saturation": -70 }] }, { "featureType": "transit", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "stylers": [{ "visibility": "simplified" }, { "saturation": -60 }] }],
			{ name: 'Styled Map' }
		);
	}

	if ($('#contact').length) {
		createMap('map', 52.0443016, -0.5941782, 8, true);
	}
}

// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it’s centered on the screen.
	const left = (screen.width / 2) - (width / 2);
	const top = (screen.height / 2) - (height / 2);

	// opens a window with the given url & set position from above
	window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
}

// Ensure the function exists on the page before we call it.
if (!!window['gdprSubscribeModal']) {
	// The form of which to attach the modal to.
	const formSelector = '#contact-form';
	// The url to link to for the privacy policy.
	const privacyPolicyURL = 'privacy-policy.html';
	// Color of the links in the modal
	const linkColour = '#000';
	// Color of the buttons in the modal.
	const buttonTextColour = '#000';

	// Call this per form.
	gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
}